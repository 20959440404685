class Header {
    parentElement: HTMLElement;
    toggleButton: HTMLElement;
    toggleIconClose: HTMLElement
    toggleIconMenu: HTMLElement;
    bodyElement: HTMLElement;

    constructor(parentElement: HTMLElement) {
        this.parentElement = parentElement;
        this.toggleButton = this.parentElement.querySelector('.header__navigation-toggle');
        this.toggleIconClose = this.parentElement.querySelector('.header__navigation-toggle--close');
        this.toggleIconMenu = this.parentElement.querySelector('.header__navigation-toggle--menu');
        this.bodyElement = document.querySelector('body');
    }

    // add event listener
    init() {
        this.toggleButton.addEventListener('click', () => {
            this.parentElement.classList.toggle('header--open');
            this.bodyElement.classList.toggle('overflow-hidden');
        });
        window.addEventListener('resize', () => {
            this.parentElement.classList.remove('header--open');
        });
    }
}

(() => {
    const headerElement = document.querySelector('.header') as HTMLElement;
    if (headerElement) {
        new Header(headerElement).init();
    }
})();
