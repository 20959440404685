class Table {
    parentElement: HTMLElement;
    monthList: Array<string>;

    /**
     * Creates an Table
     * @param parentElement htmlElement
     */
    constructor(parentElement: HTMLElement) {
        this.parentElement = parentElement;
        this.monthList = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    }

    // load data and create the table
    init() {
        const tableJsonPath = this.parentElement.getAttribute('data-path') || '/assets/downloads/table.json';
        fetch(tableJsonPath)
            .then((response) => response.json())
            .then((data) => {
                this.createTable(data);
            })
            .catch(error => {
                console.error(error);
                (this.parentElement.querySelector('.table__error') as HTMLParagraphElement).style.display = "block";
                (this.parentElement.querySelector('.table__wrapper') as HTMLParagraphElement).style.display = "none";
            })
    }

    createTable(tableData: Array<Array<string>>) {
        const headerTableRowElement = this.parentElement.querySelector('thead tr');
        headerTableRowElement.innerHTML = "";

        const tableBodyElement = this.parentElement.querySelector('tbody');
        tableBodyElement.innerHTML = "";

        let lastRowDate: Date = new Date(0);
        const reg = new RegExp(/([0-9]+)\.([0-9]+)\.([0-9]+)/);


        tableData.forEach((rowData, index) => {
            if (index === 0) {
                rowData.forEach(data => {
                    const cell = document.createElement("th");
                    cell.innerText = data;
                    headerTableRowElement.appendChild(cell);
                })
            } else {
                const newRow = document.createElement("tr");
                rowData.forEach(data => {
                    // Logic for adding month row to the table
                    try {
                        if (reg.test(data)) {
                            const [_, __, month, year] = data.match(reg);
                            const currentDate = new Date(`${month}/01/${year}`);
                            if (lastRowDate.getTime() < currentDate.getTime()) {
                                lastRowDate = currentDate;
                                const monthRow = document.createElement("tr");
                                const monthCell = document.createElement("td");
                                monthCell.classList.add('table__highlighted');
                                monthCell.colSpan = tableData[0].length;
                                monthCell.innerText = this.monthList[lastRowDate.getMonth()];
                                monthRow.appendChild(monthCell);
                                tableBodyElement.appendChild(monthRow);
                            }
                        }
                    } catch (error) {
                        console.error(error);
                    }
                    const cell = document.createElement("td");
                    cell.innerText = data;
                    newRow.appendChild(cell);
                });
                tableBodyElement.appendChild(newRow);
            }
        });
    }
}

// starting code
(() => {
    const tableList = document.querySelectorAll('.table.table--interactive') as NodeListOf<HTMLElement>;
    if (tableList && tableList.length) {
        tableList.forEach((singleTable: HTMLElement) => {
            new Table(singleTable).init();
        });
    }
})();