import Glide from '@glidejs/glide';

interface RunState {
    direction: '<' | '>';
    steps: number;
}

/**
 * @see https://glidejs.com
 */
class ImageSlider {
    parentElement: HTMLElement;
    slides: NodeListOf<HTMLElement>
    sliderWrapper: HTMLElement;
    currentSlideIndex: number;
    activeSlide: HTMLElement;
    previousSlide: HTMLElement;
    nextSlide: HTMLElement;

    /**
     * Creates an Image Slider
     * @param parentElement htmlElement
     */
    constructor(parentElement: HTMLElement) {
        this.parentElement = parentElement;
        this.slides = this.parentElement.querySelectorAll('.image-slider__item') as NodeListOf<HTMLElement>;
        this.sliderWrapper = this.parentElement.querySelector('.image-slider__wrapper') as HTMLElement;
        this.currentSlideIndex = 0;
        this.activeSlide = this.slides.item(0);
        this.previousSlide = this.slides.item(this.slides.length - 1);
        this.nextSlide = this.slides.item(1);
    }

    // create the slider
    init() {
        const slider = new Glide(this.parentElement);

        slider.on('move.after', () => {
            if ((this.activeSlide.firstElementChild as HTMLElement).offsetHeight !== 0) {
                this.sliderWrapper.style.height = `${(this.activeSlide.firstElementChild as HTMLElement).offsetHeight}px`;
            }
        });

        slider.on('run.before', (state: RunState) => {
            if (state.direction === "<") {
                if (this.currentSlideIndex === 0) {
                    this.currentSlideIndex = this.slides.length - 1;
                } else {
                    this.currentSlideIndex--;
                }
            }
            if (state.direction === ">") {
                if (this.currentSlideIndex === this.slides.length - 1) {
                    this.currentSlideIndex = 0;
                } else {
                    this.currentSlideIndex++;
                }
            }

            this.activeSlide = this.slides.item(this.currentSlideIndex);
            this.previousSlide = this.slides.item(getPreviousIndex(this.currentSlideIndex));
            this.nextSlide = this.slides.item(getNextIndex(this.currentSlideIndex));

            if ((this.activeSlide.firstElementChild as HTMLElement).offsetHeight !== 0) {
                this.sliderWrapper.style.height = `${(this.activeSlide.firstElementChild as HTMLElement).offsetHeight}px`;
            }
        });

        slider.mount();

        const getNextIndex = (currentIndex: number) => {
            return currentIndex === this.slides.length - 1 ? 0 : currentIndex + 1;
        }

        const getPreviousIndex = (currentIndex: number) => {
            return currentIndex === 0 ? this.slides.length - 1 : currentIndex - 1;
        }
    }
}

// starting code
(() => {
    const imageSliderList = document.querySelectorAll('.image-slider') as NodeListOf<HTMLElement>;
    if (imageSliderList && imageSliderList.length) {
        imageSliderList.forEach((singleImageSlider: HTMLElement) => {
            new ImageSlider(singleImageSlider).init();
        });
    }
})();
